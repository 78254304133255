import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRef, useEffect, useState } from "react";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import swal from "sweetalert";

function Login() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
    }, []);
    const onSubmit = async (data) => {
        if (data.username != "administrator") {
            /*if (data.username.length < 10 || data.username.length > 12) {
                setError("username", {
                    type: "minLength",
                    message: "Tên đăng nhập không hợp lệ"
                });
                //return;
            }*/
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu không hợp lệ"
            });
            return;
        }
        axios
            .post(`https://server.jmdesign.sale/auth/login`, data)
            .then((res) => {
                localStorage.setItem("user", res.data.data);
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    timer: 2000,
                    buttons: false
                }).then((value) => {
                    if (value) {
                        navigate("/");
                    } else {
                        setTimeout(() => {
                            navigate("/");
                        }, 2000);
                    }
                });
            })
            .catch((err) => setErr("Tài khoản hoặc mật khẩu không chính xác"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <div className="app123">
                <div className="bg"></div>

                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <img src="/img/login.jpeg"/>
                    <h1>VIETNAM FASHION</h1>
                    <div className="inputs">
                        <div>
                            <input type="text" className="ip-lg" {...register("username", { required: true })} placeholder="Tên đăng nhập" />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div>
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                            <div onClick={toggleShowPassword} className="change-visible">
                                {showPassword ? <VisibilityOff sx={{ color: "#666" }} /> : <Visibility sx={{ color: "#666" }} />}
                            </div>
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p style={{ color: "red" }}>{err}</p> : null}
                    <p className="p-lg">
                        <Link className="a-lg" to="https://secure.livechatinc.com/licence/17104248/v2/open_chat.cgi">
                            Bạn quên mật khẩu ? Hãy ấn vào đây
                        </Link>
                    </p>
                    <button className="btn-lg" type="submit">
                        Đăng nhập
                    </button>
                    <button className="btn-lg" type="button" style={{ marginTop: "0px", background: "#ff7f27" }} onClick={() => navigate("/register")}>
                        Bạn chưa có tài khoản ? Đăng ký ngay
                    </button>
                </form>
            </div>
            {/*<div className="bg-bot">
                <img src={require("../../img/bg_bot.png")} />
            </div>*/}
        </>
    );
}
export default Login;
